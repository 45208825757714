import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Layout, PreviewCompatibleImage, Gallery } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { DateText, PostTitle } from "../ui/Texts";
import { ImageContainer } from "../ui";

export const ActualitePostTemplate = ({
  title,
  image,
  gallery,
  date,
  slug,
  excerpt,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <SEO article title={title} description={excerpt} url={slug} image={image.childImageSharp.fluid.src} />
      <Container>
        <PostTitle>{title}</PostTitle>
        <DateText>{date}</DateText>
        <Wrapper padding="40px 0">
          <ImageContainer marginB="2rem">
            <PreviewCompatibleImage imageInfo={image} />
          </ImageContainer>
          <PostContent className="markdown" content={content} />
          {gallery && <Gallery photos={gallery} />}
        </Wrapper>
      </Container>
    </>
  );
};

ActualitePostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  gallery: PropTypes.array,
  date: PropTypes.string,
  slug: PropTypes.string,
  excerpt: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const ActualitePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ActualitePostTemplate
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        gallery={post.frontmatter.gallery}
        date={post.frontmatter.date}
        slug={post.fields.slug}
        excerpt={post.excerpt}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

ActualitePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ActualitePost;

export const pageQuery = graphql`
  query ActualitePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 100)
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "D MMMM YYYY", locale: "fr")
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          image {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;
